<template>

	<mdb-container class="mt-5">

		<mdb-card>
			<mdb-card-body class="card-body-padding text-center">

				<p class="mb-4">
					<icon name="smile" type="uei-icon" class="text-primary huge-uei-icon">
						<span class="path1"></span>
						<span class="path2"></span>
						<span class="path3"></span>
						<span class="path4"></span>
						<span class="path5"></span>
						<span class="path6"></span>
						<span class="path7"></span>
					</icon>
				</p>

				<h3 class="font-weight-bold uppercase text-info mt-4">{{ $t('newApplication.approvedTitle') }}</h3>

				<p class="my-4 max-width-p">{{ $t('newApplication.approvedText') }}</p>

				<h3 class="text-success font-weight-bold max-width-p" v-if="amountApproved && rentalAmount">{{ $t('newApplication.approvedForAmountAndRental', {amountApprovedText, rentalAmountText}) }}</h3>
				<h3 class="text-success font-weight-bold" v-else-if="rentalAmount">{{ $t('newApplication.approvedForRentalAmount', {rentalAmountText}) }}</h3>
				<h3 class="text-success font-weight-bold" v-else-if="amountApproved">{{ $t('newApplication.approvedForAmount', {amountApprovedText}) }}</h3>

				<h3 class="text-info font-weight-bold mt-4 mb-0">App # C{{ appId }}</h3>

			</mdb-card-body>
		</mdb-card>

		<div class="form-group button-row">

			<btn
				type="button"
				color="primary"
				size="lg"
				rounded
				block
				@click="$router.push({name: 'Dashboard'})"
			>{{ $t('newApplication.homeBtn') }}</btn>

			<btn
				v-if="this.authUser.vendorInternalOrExternal && this.authUser.vendorInternalOrExternal == 'External Vendor'"
				type="button"
				color="info"
				size="lg"
				rounded
				block
				@click="$router.push({name: 'Assets', params: $route.params})"
			>{{ $t('newApplication.generateDocs') }}</btn>

		</div>

	</mdb-container>

</template>

<script>

	import BaseMixin from './BaseMixin';
	import { formatCurrency } from '../../../elements/globalDirectives/CurrencyLocale';
	import { mapState, mapActions } from 'vuex';

	export default {

		mixins: [
			BaseMixin
		],

		data() {

			return {
				appId: null,
				amountApproved: 0,
				rentalAmount: 0
			};

		},

		computed: {

			...mapState({
				locale: state => state.app.locale,
				authUser: state => state.auth.authUser
			}),

			amountApprovedText() {

				return formatCurrency(this.amountApproved, this.locale);

			},

			rentalAmountText() {

				return formatCurrency(this.rentalAmount, this.locale);

			},

		}

	};

</script>

<style lang="scss" scoped>

</style>
