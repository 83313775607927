import { render, staticRenderFns } from "./CreditBureauNotFound.vue?vue&type=template&id=343b1405&scoped=true&"
import script from "./CreditBureauNotFound.vue?vue&type=script&lang=js&"
export * from "./CreditBureauNotFound.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "343b1405",
  null
  
)

export default component.exports