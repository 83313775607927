<template>

	<form @submit.prevent="onSubmit" novalidate>

		<steps :current="3" :total="4" label="Sign" />

		<mdb-container v-if="closeTab" class="mt-5">
			<mdb-card>
				<mdb-card-body class="card-body-padding text-center">
					<p class="text-primary"><strong>{{ $t("sign.closeTab") }}</strong></p>
				</mdb-card-body>
			</mdb-card>
		</mdb-container>

		<mdb-container v-if="!closeTab && isEmailSentToCustomer" class="mt-5">
			<mdb-card>
				<mdb-card-body class="card-body-padding text-center">
					<i class="fa fa-circle-notch fa-spin fa-2x text-secondary mb-3"></i>
					<p class="text-primary"><strong>{{ $t("sign.docSentToCustomer") }}</strong></p>
					<p class="text-primary"><strong>{{ $t("sign.docSentToCustomer2") }}</strong></p>

					<button
						class="button btn"
						@click="download()"
					>{{ $t('sign.download') }}</button>
				</mdb-card-body>
			</mdb-card>
		</mdb-container>

		<mdb-container v-if="!closeTab && !isEmailSentToCustomer" class="mt-5">

			<mdb-card>
				<mdb-card-body class="card-body-padding text-center">

					<div v-if="fetchingApp">
						
						<p class="mb-4">
							<icon name="smile" type="uei-icon" class="text-primary huge-uei-icon">
								<span class="path1"></span>
								<span class="path2"></span>
								<span class="path3"></span>
								<span class="path4"></span>
								<span class="path5"></span>
								<span class="path6"></span>
								<span class="path7"></span>
							</icon>
						</p>

						<p class="text-primary"><strong>PLEASE WAIT</strong></p>
						
					</div>


					<div v-if="this.application.data.envelopeId && !isDocSigned && !fetchingApp">
						<i class="fa fa-circle-notch fa-spin fa-2x text-secondary mb-3"></i>
						<p>{{ $t("sign.alreadyRequested") }}</p>
						<button
							class="button btn"
							@click="download()"
						>{{ $t('sign.download') }}</button>
						<hr class="mb-5 mt-5">
					</div>

					<div v-if="isDocSigned && !fetchingApp">
						<i class="fa fa-check fa-2x text-secondary mb-3"></i>
						<p>{{ $t("sign.alreadySigned1") }}</p>
						<p>{{ $t("sign.alreadySigned2") }}</p>
						<p>{{ $t("sign.quebecNotice") }}</p>
						<button
							class="button btn"
							@click="download()"
						>{{ $t('sign.download') }}</button>
						<hr class="mb-5 mt-5">
					</div>

					<div v-if="!urls && !fetchingApp && !this.isQC">

						<div class="mb-4">
							<label for="program" class="h4 font-weight-bold">{{ $t("sign.customerMethod") }}</label>
							<select v-model="customerSignMethod" class="form-control" name="signMethod">
								<option value="Embed" key="Embed">In Person</option>
								<option value="Email" key="Email">By Email ({{ application.data.email }})</option>
							</select>
						</div>

						<div class="mb-3" v-if="hasCoApplicant">
							<label for="program" class="h4 font-weight-bold">{{ $t("sign.coCustomerMethod") }}</label>
							<select v-model="coCustomerSignMethod" class="form-control" name="signMethod">
								<option value="Embed" key="Embed">In Person</option>
								<option value="Email" key="Email">By Email ({{ appDetails.coApplicant.email }})</option>
							</select>
						</div>

						<button
							class="button btn"
							@click="sign()"
						>{{ $t('sign.request') }}</button>
						
					</div>

					<div v-if="!urls && !fetchingApp && this.isQC">

						<button
							class="button btn"
							@click="sign()"
						>{{ $t('sign.generatePdf') }}</button>
						
					</div>

					<div v-if="urls">

						<p v-if="!this.isDocSigned">{{ $t('sign.clickToSign') }}</p>

						<ul class="sign-links">
							<li v-for="url in urls" :key="url.url">
								<a
									@click="openUrl(url.url, url.roleName)"
									v-if="url.url && !isSigned[url.roleName]"
									href=""
									target="_blank"
									class="btn"
								>{{ $t('sign.signAs') + $t('sign.' + url.roleName) }}</a>
							</li>
						</ul>

					</div>
					
					<h3 v-if="false" class="font-weight-bold uppercase text-info mt-4">{{ $t('newApplication.submittingTitle') }}<span class="ellipsis"></span></h3>

				</mdb-card-body>
			</mdb-card>

			<div class="form-group button-row">

				<submit-btn
					:loading="goingBack"
					type="button"
					@click="goBack({name: 'FinancingProgram', params: $route.params})"
				>{{ $t('forms.back') }}</submit-btn>

				<submit-btn
					type="submit"
					v-if="isDocSigned"
					@click="uploadToEdge()"
				>{{ $t('forms.next') }}</submit-btn>

			</div>

		</mdb-container>

	</form>

</template>

<script>

	// import ApplicationMixin from '../../application/components/ApplicationForms/Application/ApplicationMixin';
	import FormsMixin from './ApplicationForms/FormsMixin';
	import { mapActions } from 'vuex';
	import axios from 'axios';
	import { getSignUrl, sendUploadTrigger, getApplication, checkSignStatus, downloadPdf } from '../../api';
	// import store from '../../store';
	// import { saveLoading } from '../../vuex/actions';
	// import dotCase from 'dot-case';
	import { kebabCase } from 'change-case';
	import fs from 'fs';
	const iconv = require('iconv-lite');

	
	axios.defaults.headers.common['Content-Type'] = 'application/json';
	axios.defaults.headers.common['Accept'] = 'application/json';

	export default {

		mixins: [
			// ApplicationMixin
			FormsMixin
		],

		data() {

			return {

				isDocSigned: false,
				urls: null,
				isSigned: {
					agent: false,
					customer: false,
					coCustomer: false,
				},
				appDetails: null,
				signees: [
					'customer',
					'coCustomer'
				],
				allSigned: false,
				envelopeId: null,
				customerSignMethod: null,
				coCustomerSignMethod: null,
				hasCoApplicant: null,
				fetchingApp: true,
				isTimeToCheckStatus: false,
				closeTab: false,
				isEmailSentToCustomer: false,
				isCoCustomerSigned: false,
				isQC: false,
				isShowPopupNotice: false,
			};

		},

		watch: {
			'isDocSigned': {

				handler(val) {

					if (val) {

						// this.navToUploads();

					}

				}
				
			},
			'isTimeToCheckStatus': {

				handler(val) {

					if (val) {

						console.log('isTimeToCheckStatus', this.isTimeToCheckStatus);

						this.checkStatus()

					}

				}

			}

		},

		mounted() {

			this.isQC = this.authUserVendor.province == 'QC';

			console.log('isQC', this.isQC);

			let curUrl = location.href;

			if (curUrl.indexOf('complete') > -1) {

				try {

					this.closeTab = true;
					
					// this.customerSigned = true;

					this.isTimeToCheckStatus = true;

				} catch (e) {

					window.close();

				}
				
				window.close();

				var host = window.opener;

				window.focus();

				window.close();

				try {

					host.focus();

				} catch(e) {

					console.log(e);

				}

			}

			setInterval(() => {

				if (!this.isDocSigned) {

					console.log('check status');
					console.log('this.isDocSigned', this.isDocSigned);
					this.checkStatus();

				}

			}, 30000);

			this.getApp();

			this.hasCoApplicant = this.application.data.hasCoApplicant == 'Yes' ? true : false;

			console.log('this', this);

		},

		methods: {

			...mapActions([
				'showErrorAlert',
				'showInfoAlert',
				'removeAlerts'
			]),

			getUrl(id) {

				return {
					name: 'UploadFilesStep1',
					params: {
						status: kebabCase(id)
					}
				};

			},

			openUrl(url, roleName) {

				var win = window.open(url, '_blank');

				win.focus();

				this.isSigned[roleName] = true;

				console.log('customerSignMethod', this.customerSignMethod);
				console.log('coCustomerSignMethod', this.coCustomerSignMethod);

				if (

					this.customerSignMethod == null

					||

					(this.customerSignMethod && this.customerSignMethod == 'Email')

				) {
					
					this.isSigned['customer'] = true;

				}

				if (

					this.coCustomerSignMethod == null

					||

					(this.coCustomerSignMethod && this.coCustomerSignMethod == 'Email')

				) {
						
					this.isSigned['coCustomer'] = true;

				}

				if (
					this.isSigned['agent']
					&& this.isSigned['customer']
					&& this.isSigned['coCustomer']
				) {
					
					this.isEmailSentToCustomer = true;

					setInterval(() => {

						if (!this.isDocSigned) {

							console.log('check status');
							console.log('this.isDocSigned', this.isDocSigned);
							this.checkStatus();

						}

					}, 30000);

				}

				

				console.log('isSigned', this.isSigned);

				// if (
				// 	roleName == 'agent'
				// 	&& this.customerSignMethod == 'Email'
				// ) {

				// 	// check if coCustomer does not exist or doesn't need to sign here (aka sign via email)
				// 	if (
						
				// 		(
				// 			this.coCustomerSignMethod == 'Embed'
				// 			&& this.isCoCustomerSigned
				// 		)

				// 		||

				// 		(
				// 			!this.coCustomerSignMethod // no coCustomer
				// 		)

				// 	) {
				// 		this.isEmailSentToCustomer = true;
				// 	}

				// } else if (
				// 	roleName == 'customer'
				// 	&& this.coCustomerSignMethod == 'Embed'
				// ) {

				// }

			},

			async getApp() {

				this.saveLoading(true);

				let app = await getApplication(this.$route.params.applicationId);
				console.log('app', app);
				this.appDetails = app;

				if (
					app.application
					&& !app.application.creditProgramID
				) {

					// this.showErrorAlert('Please select a program first.');

					// return this.$router.push({
					// 	name: 'FinancingProgram',
					// 	params: this.$route.params
					// });

				}

				this.hasCoApplicant = app.application.hasCoApplicant == 'Yes' ? true : false;
				this.isDocSigned = app.application.isDocSigned;

				if (app.application.envelopeId) {

					this.envelopeId = app.application.envelopeId;

					await this.checkStatus();

				}

				this.fetchingApp = false;

				this.saveLoading(false);

			},

			async checkStatus() {

				// this.saveLoading(true);

				console.log('this.envelopeId', this.envelopeId);

				var signResult = await checkSignStatus({
					id: this.$route.params.applicationId,
					envelopeId: this.envelopeId
				}).catch(error => {

					let errorMsg = this.$i18n.t('fatalError.internal');

					if (error.response && error.response.data && error.response.data.errorMessage) {

						errorMsg = error.response.data.errorMessage;

					} else if (error.message) {

						errorMsg = error.message;

					}

					if (errorMsg) {

						this.showErrorAlert(errorMsg);

					}

					this.saveLoading(false);

				});

				console.log('signResult', signResult);
				
				if (
					signResult
					&& signResult.data
					&& signResult.data.results
					&& signResult.data.results == "completed"
				) {

					this.isDocSigned = true;

					this.allSigned = true;

					this.isEmailSentToCustomer = false;

					// console.log('this.isDocSigned', this.isDocSigned);

				} else {

					// this.envelopeId = null;
					this.fetchingApp = false;

				}

				// this.saveLoading(false);

			},

			navToUploads() {

				return this.$router.push({
					name: 'UploadFilesStep1',
					params: this.$route.params
				});
			
			},

			async download(event) {

				this.saveLoading(true);

				let data;

				try {
					
					data = await downloadPdf({
						id: this.$route.params.applicationId,
					}).catch(error => {

						let errorMsg = this.$i18n.t('fatalError.internal');

						if (error.response && error.response.data && error.response.data.errorMessage) {

							errorMsg = error.response.data.errorMessage;

						} else if (error.message) {

							errorMsg = error.message;

						}

						if (errorMsg) {

							this.showErrorAlert(errorMsg);

						}

						this.saveLoading(false);

					});

				} catch (error) {

					this.saveLoading(false);
					console.log('data', data);
					this.showErrorAlert(error);

				}

				let byteCharacters = atob(data.base64);
				let byteNumbers = new Array(byteCharacters.length);

				for (let i = 0; i < byteCharacters.length; i++) {
					byteNumbers[i] = byteCharacters.charCodeAt(i);
				}
				
				let byteArray = new Uint8Array(byteNumbers);
				let file = new Blob([byteArray], { type: 'application/pdf;base64' });
				let fileURL = URL.createObjectURL(file);
				let newWin = window.open(fileURL);

				if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
					let errorMsg = this.$i18n.t('sign.popupNotice');
					this.showErrorAlert(errorMsg);
					this.isShowPopupNotice = true;
				}

				this.saveLoading(false);

			},

			async sign(event) {

				console.log('event', event);

				this.removeAlerts();

				if (!this.$route.params.applicationId) {

					this.showErrorAlert('Input application id in the URL');
					
					return {
						errorMessage: 'Application Id not found'
					};
				
				}

				console.log(this.$route.params.applicationId);

				let applicationId = this.$route.params.applicationId;
				
				this.saveLoading(true);

				console.log('location.href', location.href);

				let data = await getSignUrl({
					id: applicationId,
					url: location.href,
					customerSignMethod: this.customerSignMethod,
					coCustomerSignMethod: this.coCustomerSignMethod
				}).catch(error => {

					let errorMsg = this.$i18n.t('fatalError.internal');

					if (error.response && error.response.data && error.response.data.errorMessage) {

						errorMsg = error.response.data.errorMessage;

					} else if (error.message) {

						errorMsg = error.message;

					}

					if (errorMsg) {

						this.showErrorAlert(errorMsg);

					}

					this.saveLoading(false);

				});

				console.log('data', data);

				this.urls = data;

				console.log('data[0].envelopeId', data[0].envelopeId);

				this.envelopeId = data[0].envelopeId;

				// this.showInfoAlert('Loading ...');

				let redirectUrl = this.getUrl(applicationId);

				console.log('redirectUrl', redirectUrl);

				this.isDocSigned = false;

				this.saveLoading(false);

				// HelloSign.init("9fe5fcec78217a382169a8299ba63f16");
				
				// // return;

				// await HelloSign.open({
				// 	url: data.result.sign_url,
				// 	debug: true,
				// 	skipDomainVerification: true,
				// 	allowCancel: false,
				// 	// redirectUrl: '/upload-files/',
				// 	// container: document.getElementById,
				// 	messageListener: function (eventData) {
						
				//		// alert("HelloSign event received")	
				//		// this.showInfoAlert('Uploading to EDGE')	
				//		console.log('eventData', eventData);
				//		// this.setLoading(true)	
				//		if (eventData.event == "signature_request_signed") { // TODO: capture more scenario	
				//			console.log('calling sendUploadTrigger');

				//			sendUploadTrigger({
				//				signatureRequestId: data.signatureRequestId,
				//				id: applicationId
				//			}).then(res => {
				//				// this.navToUploads();
				//				// this.isDocSigned = true; // not working
				//				return true;
				//			})	
				//			
				//	}
				// }).then(res => {
	
				// 	this.saveLoading(false);
				// 	console.log('doc signed');
				// 	this.isDocSigned = true;
				// 	this.navToUploads();

				// });

				this.saveLoading(false);
				// this.navToUploads();

			},

			uploadToEdge() {

				this.saveLoading(true);

				sendUploadTrigger({

					envelopeId: this.envelopeId || this.application.data.envelopeId,
					id: this.applicationId

				}).then(res => {

					console.log(res);

					this.saveLoading(false);
					this.navToUploads();
					return true;

				}).catch(error => {

					let errorMsg = this.$i18n.t('fatalError.internal');

					if (error.response && error.response.data && error.response.data.errorMessage) {

						errorMsg = error.response.data.errorMessage;

					} else if (error.message) {

						errorMsg = error.message;

					}

					if (errorMsg) {

						this.showErrorAlert(errorMsg);

					}

				});

				// goBack({name: 'UploadFilesStep1', params: $route.params});

			}

		}

	};

</script>

<style lang="scss" scoped>
.sign-links {
	list-style: none;
	padding-left: 0px;
}
select {
	display: inline!important;
}
</style>